/* This file contains JS codes that are common to both desktop and mobile to avoid duplicate */

function validationContactMagFormCompulsory(field) {
    if(field.val() === '') {
        field.addClass("inputErr");
        return false;
    }

    field.removeClass('inputErr');
    return true;
}

function validationContactMagFormEmail(field) {
    if(field.val() === '' || validateEmail(field.val()) === false) {
        field.addClass("inputErr");
        return false;
    }

    field.removeClass('inputErr');
    return true;
}

function validationContactMagFormTel(field) {
    if (field.val() === '' || field.intlTelInput("isValidNumber") === false || field.intlTelInput("getNumberType") == 0) {
        field.addClass("inputErr");
        return false;
    }

    field.removeClass('inputErr');
    return true;
}

function validationContactMagFormOption(field, opt1, opt2) {
    if(field.length && !opt1.is(":checked") && !opt2.is(":checked")) {
        field.addClass("inputErr");
        return false;
    }

    field.removeClass("inputErr");
    return true;
}

function checkContactFormOnBlur (el) {
    el.find("#clientNom").blur(function() {
        if (!validationContactMagFormCompulsory (el.find("#clientNom")) ) {
            return false;
        }
    });

    el.find("#clientPrenom").blur(function() {
        if (!validationContactMagFormCompulsory (el.find("#clientPrenom")) ) {
            return false;
        }
    });

    el.find("#clientMail").blur(function() {
        if (!validationContactMagFormEmail (el.find("#clientMail")) ) {
            return false;
        }
    });

    // Remove for WP-27674
    // el.find("#clientTel, #numTelephone").blur(function() {
    //     if (!validationContactMagFormTel (el.find("#clientTel, #numTelephone")) ) {
    //         return false;
    //     }
    // });

    el.find("#clientMessage").blur(function() {
        if (!validationContactMagFormCompulsory (el.find("#clientMessage")) ) {
            return false;
        }
    });

    el.find("#wrapper_optin").change(function() {
        if (!validationContactMagFormOption (el.find("#wrapper_optin"), el.find("#optin_y"), el.find("#optin_n")) ) {
            return false;
        }
    });

    el.find("#wrapper_optin_sms").change(function() {
        if (!validationContactMagFormOption (el.find("#wrapper_optin_sms"), el.find("#optin_sms_y"), el.find("#optin_sms_n")) ) {
            return false;
        }
    });

    return true;
}

function checkContactFormOnClick (el) {
    var form_valid = validationContactMagFormCompulsory (el.find("#clientNom"));

    if(!validationContactMagFormCompulsory (el.find("#clientPrenom"))) {
        form_valid = false;
    }

    if(!validationContactMagFormEmail (el.find("#clientMail"))) {
        form_valid = false;
    }

    // Remove for WP-27674
    // if(!validationContactMagFormTel (el.find("#clientTel, #numTelephone")) && el.find("#clientTel, #numTelephone").val() !== '') {
    //     form_valid = false;
    // }

    if(!validationContactMagFormCompulsory (el.find("#clientMessage"))) {
        form_valid = false;
    }

    if(!validationContactMagFormOption (el.find("#wrapper_optin"), el.find("#optin_y"), el.find("#optin_n"))) {
        form_valid = false;
    }

    if(!validationContactMagFormOption (el.find("#wrapper_optin_sms"), el.find("#optin_sms_y"), el.find("#optin_sms_n"))) {
        form_valid = false;
    }

    return form_valid;
}
