var save_id = 0;
var cpt_save = 0;
var from_range = false; //for wishlist

function addEvent(elem, event, name_function) {

    if (document.all) {

        elem.attachEvent("on" + event, name_function)
    } else {

        elem.addEventListener(event, name_function, false);
    }

}

function delEvent(elem, event, name_function) {

    if (document.all) elem.detachEvent("on" + event, name_function);
    else elem.removeEventListener(event, name_function, false);

}

// empeche les evenements de remonter dans l'arbre

function doNothing(e) {

    if (document.all) {
        if (!e) var e = window.event;
        e.cancelBubble = true;
    } else {
        e.stopPropagation();
    }
    return false;
}
// popup guide des tailles
function showsizeguide() {
    var elname = 'sizeguidebox';

    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var shade = document.getElementById('shade');

    modbox.style.position = "absolute";
    shade.style.display = "block";

    addEvent(shade, 'click', closesizeguide);

    addEvent(document.getElementById('menu_sizeguide'), 'click', doNothing);

    modbox.style.display = "block";

    window.scrollTo(0, actualposition);
}

function closesizeguide() {
    var elname = 'sizeguidebox';
    var shade = document.getElementById('shade');
    var modbox = document.getElementById(elname);
    modbox.style.display = "";

    delEvent(shade, 'click', closesizeguide);
    delEvent(modbox, 'click', closesizeguide);
    delEvent(document.getElementById('menu_sizeguide'), 'click', doNothing);
    closemodal();
}

// switch guide des tailles
function switch1(div) {

    if (document.getElementById('sizeguide_femme')) {
        var option = ['sizeguide_femme', 'sizeguide_homme', 'sizeguide_lingerie', 'sizeguide_fille', 'sizeguide_garcon', 'sizeguide_bebe'];
        for (var i = 0; i < option.length; i++) {
            obj = document.getElementById(option[i]);
            obj.style.display = (option[i] == div) ? "block" : "none";
        }
    }

    cpt_save++;

}

$("#site_global_wrap").on("click", ".toggleInfos", function () {

    $(this).parent(".desc_pannel").toggleClass("opened");
});

function showmodal(produit_id) {
    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById('modbox');
    var cmodbox = document.getElementById('cmodbox');
    var panmodbox = document.getElementById('panmodbox');
    var shade = document.getElementById('shade');
    document.body.style.overflow = 'hidden';

    cmodbox.innerHTML = '';
    panmodbox.innerHTML = '';

    modbox.style.marginTop = (actualposition - 260) + "px";
    shade.style.display = "block";
    modbox.style.display = "block";
    document.getElementsByTagName("html")[0].style.overflow = "hidden";

    window.scrollTo(0, actualposition);
}

var addToCartTmpl;

function buildItemAddedModbox(id_produit, is_not_produit, parent_element) {

    var form = document.getElementById('prod_info_' + id_produit);
    var closing_method = (is_not_produit == 'true') ? 'window.location.reload()' : 'close_lightbox(\'modbox\')';
    var lot_nb = form.getValue('produit_lot_nb', parseFloat);

    var itm_name = $('#itm_name', form).get(0).textContent.trim();

    if ($('#itm_feat', form).length > 0) {

        var itm_feat = $('#itm_feat', form).get(0).textContent.trim();
    }

    var itm_price = $("#prixU", form).val();
    if (lot_nb > 1) {
        itm_price = formatPrice(parseFloat(itm_price) * parseInt(lot_nb));
    } else {
        itm_price = formatPrice(parseFloat(itm_price));
    }

    if (form) {
        var itm_length = parseFloat(form.elements.namedItem('qteProd').value);
        if (lot_nb > 1 || itm_length > 1) {
            itm_length += ' ' + Translator.translate('items');
        } else {

            itm_length += ' ' + Translator.translate('item');
        }

        if (itm_length == 1) {

            itm_length += ' ' + Translator.translate('added_to_cart_sn');
        } else if (form.getValue('produit_lot_nb') == '1') {

            itm_length += ' ' + Translator.translate('added_to_cart_pl');
        }
    }

    $('#shade').after($('#modbox'));

    document.getElementById('modbox').innerHTML = Mustache.render(addToCartTmpl, {
        closing_method: closing_method,
        itm_name: itm_name,
        itm_feat: itm_feat,
        itm_price: itm_price,
        itm_length: itm_length
    });

    if (document.getElementById('nbr_visu_0')) {
        var path = $('img', parent_element + '#nbr_visu_0').attr('src').replace('/mini/', '/medium/');
        document.getElementById('modbox_visu').innerHTML = '<img id="modbox_visu" src="' + path + '" width="291" height="194" />';
    } else {
        document.getElementById('modbox_visu').innerHTML = '<img id="modbox_visu" src="' + path_relative_root + ' img/novisu_horizontal.jpg" width="291" height="194" />';

    }
}

function buildLookAddedModbox(is_not_produit) {

    var form = document.getElementById('slide_look_detail');
    var closing_method = (is_not_produit == 'true') ? 'window.location.reload()' : 'close_lightbox(\'modbox\')';
    var look_total = formatPrice(parseFloat(document.getElementById('look_total').value));
    var look_items = [];

    $('fieldset', form).each(function () {

        var itm_quantity = parseFloat($('.qte_select_wrapper select', this).get(0).value);

        if (itm_quantity > 0) {

            var new_look_itm = {};

            new_look_itm.itm_visu = $('.visu_look', this).attr('src').replace('/medium/', '/mini/');
            new_look_itm.itm_name = this.getValue('itm_name');
            new_look_itm.itm_quantity = itm_quantity;
            new_look_itm.itm_total = formatPrice(this.getValue('itm_price', parseFloat) * itm_quantity);

            look_items.push(new_look_itm);
        }
    });

    $('#shade').after($('#modbox'));

    document.getElementById('modbox').innerHTML = Mustache.render(addToCartTmpl, {
        closing_method: closing_method,
        look_total: look_total,
        look_items: look_items
    });

    $('.price_look .price_total').html(look_total);
}

function showsendfriendmodal(product_id, path_web, type) {

    var elname = 'sendfriendbox';

    var actualposition = $(document).scrollTop;
    var $modbox = $('#' + elname);
    var $shad = $('#shad');

    $('#product_sendfriend_nok,#product_sendfriend_ok').html('');
    $('#product_sendfriend_nok, #product_sendfriend_ok').css({
        display: 'none'
    });
    $('p.popup_title').show();

    prodGetProductDisplay(product_id, path_web, type);

    $('#shad').fadeTo("slow", 0.5).queue(function () {
        $('#sendfriendbox').slideDown("slow");
        $(this).dequeue();
    });

    $shad.on({
        'click': function () {
            closesendfriendmodal();
        }
    })

    $('body').append($('#sendfriendbox').detach());

    window.scrollTo(0, actualposition);

    look_add = false;
}

function closesendfriendmodal() {
    var modbox = document.getElementById('sendfriendbox');
    var shade = document.getElementById('shade');
    var shad = document.getElementById('shad');

    $('#sendfriendbox').slideUp("slow").queue(function () {
        $('#shade').fadeTo("slow", 0);
        $(this).dequeue();
        shade.style.display = 'none';
        shad.style.display = 'none';
        modbox.style.display = 'none';
    });
    document.getElementById('sendfriend_alert_mail').style.display = "none";
    document.getElementById('sendfriend_prodinfos').style.display = "block";
    document.getElementById('sendfriend_form_to').style.display = "block";
    document.getElementById('sendfriend_form_froms').style.display = "block";
    document.getElementById('sendfriend_form_buttons').style.display = "block";
    document.getElementById('sendfriend_form_needed').style.display = "block";
    document.body.style.overflow = '';
    document.getElementsByTagName("html")[0].style.overflow = "";

    delEvent(shade, 'click', closesendfriendmodal);
    delEvent(shad, 'click', closesendfriendmodal);

    if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "visible";
        }

    }
}

function closesendfriendmodalfromconfirm() {
    var modbox = document.getElementById('sendfriendbox');
    var shade = document.getElementById('shade');
    var shad = document.getElementById('shad');
    modbox.style.marginLeft = '';
    shade.style.display = "none";
    modbox.style.display = "none";
    document.body.style.overflow = '';
    document.getElementsByTagName("html")[0].style.overflow = "";
    document.getElementById('sendfriend_prodinfos').style.display = "block";
    document.getElementById('sendfriend_form_to').style.display = "block";
    document.getElementById('sendfriend_form_froms').style.display = "block";
    document.getElementById('sendfriend_form_buttons').style.display = "block";
    document.getElementById('sendfriend_form_needed').style.display = "block";
    document.getElementById('sendfriend_alert_mail').innerHTML = "";
    document.getElementById('sendfriend_alert_mail').style.display = "none";

    delEvent(shade, 'click', closesendfriendmodal);
    delEvent(shad, 'click', closesendfriendmodal);

    if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "visible";
        }

    }

}

function closemodal() {

    var modbox = document.getElementById('modbox');
    var errbox = document.getElementById('errbox');
    var cmodbox = document.getElementById('cmodbox');
    var shade = document.getElementById('shade');
    var shad = document.getElementById('shad');

    if (cmodbox) {

        cmodbox.innerHTML = '';
    }

    if (modbox) {

        modbox.style.marginLeft = '';
        modbox.style.display = 'none';
    }

    if (shade) {

        shade.style.display = 'none';
    }

    if (shad) {

        shade.style.display = 'none';
    }

    if (errbox) {

        errbox.style.display = 'none';
    }

    document.body.style.overflow = '';
    document.getElementsByTagName("html")[0].style.overflow = '';

    if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "visible";
        }

    }
}

function showLightboxGamme(id, idshort) {
    var shad = document.getElementById("shad"),
        $obj = $("#" + id);

    $("body").append($obj.detach());

    if (
        $(".input_check.color:not(:disabled)", $obj).length >= 1 &&
        !$(".input_check.color:not(:disabled)", $obj).is(":checked")
    ) {
        $(".input_check.color:not(:disabled):first", $obj)
            .prop("checked", true)
            .get(0)
            .onchange();
    }

    $obj.css({
        display: "block",
        opacity: 0
    }).animate({
        opacity: 1
    }, 200);

    $("#shad")
        .one("click", closeMultiShad.bind(this, id))
        .css({
            display: "block",
            opacity: 0
        })
        .animate({
            opacity: 0.6
        }, 200);

    if (idshort !== undefined) {
        showQteListeGamme("liste_qte_" + idshort, idshort, path_relative_root);
    }
}

function waitingAddToBasket(evt, form_elm, from) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var tailleProd = form_elm.getValue('tailleProd');
    var item_color = form_elm.getValue('couleurProd');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var loader;
    var product_ref = form_elm.getValue('product_ref');

    evt.preventDefault();

    if (from === undefined) {
        from = '';
    }

    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    if (from == 'product') {
        if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {
            loader = $(this.nextElementSibling);
            loader.removeClass("cache");
            loader.addClass("loading");

        } else {
            $("#bloc_add_basket #btn_add_cart").addClass('cache');
            $("#bloc_add_basket .button.loader").addClass('loading');
            $("#bloc_add_basket .button.loader").removeClass('cache');

            $("#bloc_btn_active" + id).attr('class', 'cache');
            $("#bloc_btn_loader" + id).attr('class', 'f_right');
        }
    }

    if (is_lightbox_specifique == 'true') {
        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, product_ref, tailleProd, item_color);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, product_ref, tailleProd, item_color) {

    var id = '';

    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true) {
        id += '_' + produit_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {
            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var parent_element = '';

    if ($('#lightbox_achat_express').css('display') == 'block') {
        parent_element = '#lightbox_achat_express ';
        $("#shad").hide();
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {
        if (dataLayer === undefined || dataLayer.length != true) {
            dataLayer.push({
                'event': 'addToBasket',
                'ref': product_ref,
                'refSizeColor': product_ref + tailleProd + item_color
            });
        }
        if (from == 'basket') {
            location.reload();
            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        omnitureEvent = '';
        //On récupère la quantité sélectionnée pour le produit avant de l'ajouter au total du panier
        var quantiteSelect = 0;
        if ($('#lightbox_achat_express .product_info_wrapper #qteProd').length) {
            quantiteSelect = $('#lightbox_achat_express .product_info_wrapper #qteProd').val();
        } else {
            quantiteSelect = $('#qteProd').val();
        }

        var quantiteInitiale = $('#cart_length').attr('data-cart');
        var quantiteTotaleUpdate = parseInt(quantiteSelect) + parseInt(quantiteInitiale);

        if (quantiteTotaleUpdate === 0) {
            $('#cart_length').html(quantiteTotaleUpdate + ' ' + Translator.translate('items'));
            $('#cart_length').attr('data-cart', quantiteTotaleUpdate);
            omnitureEvent = '';
        } else if (quantiteTotaleUpdate == 1) {
            $('#cart_length').html(quantiteTotaleUpdate + ' ' + Translator.translate('item'));
            $('#cart_length').attr('data-cart', quantiteTotaleUpdate);
            $('.mini_cart', '#top_wrapper').attr('href', path_web + create_link('order_basket'));
            $('#cart_length').css('display', '');

            omnitureEvent = 'scOpen,scAdd';
        } else {
            $('#cart_length').html(quantiteTotaleUpdate + ' ' + Translator.translate('items'));
            $('#cart_length').attr('data-cart', quantiteTotaleUpdate);
            $('.mini_cart', '#top_wrapper').attr('href', path_web + create_link('order_basket'));
            omnitureEvent = 'scAdd';
        }

        var prix_unit = $(parent_element + "#" + prix_id).val();
        var lot_nb = $('#produit_lot_nb').val();
        if (lot_nb > 0) {
            prix_unit = prix_unit * lot_nb;
        }

        if (loader) {
            // cf: function waitingAddToBasket logic
            loader.removeClass('loading').addClass('cache');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');

            $("#bloc_add_basket #btn_add_cart").removeClass('cache');
            $("#bloc_add_basket .button.loader").removeClass('loading');
            $("#bloc_add_basket .button.loader").addClass('cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {
            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        $("#shade").after($("#modbox"));
        $("#lightbox_achat_express, .lightbox_achat_express, #shade").hide();

        // Updated shopping cart top
        var response_basket = ajax_file(path_web + create_link('ajax_show_basket'));

        $("#cart_top").html(response_basket);

        CartShow(true);

        setTimeout(function(){
            productVisitedSwiper(5);
        }, 500);

    } else {

        if (loader) {
            loader.css('display', 'none');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    if (parent_element == '') {
        eval(toEval);
    }

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");
            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }

    if (!$('#site_global_wrap #product_selects.bundle').length) {
        $('#site_global_wrap #product_selects').show();
    }

    return false;
}

function displayOnglet(pDiv) {

    if (pDiv == "ongletSavoirPlus") {
        if (document.getElementById('ongletCaracteristiques'))
            document.getElementById('ongletCaracteristiques').style.display = 'none';
    }

    if (pDiv == "ongletCaracteristiques") {
        if (document.getElementById('ongletSavoirPlus'))
            document.getElementById('ongletSavoirPlus').style.display = 'none';
    }

    if (pDiv == "ongletGammes") {
        if (document.getElementById('ongletCaracteristiques'))
            document.getElementById('ongletCaracteristiques').style.display = 'none';
        if (document.getElementById('ongletSavoirPlus'))
            document.getElementById('ongletSavoirPlus').style.display = 'none';
        if (document.getElementById('ongletAssociations'))
            document.getElementById('ongletAssociations').style.display = 'none';
    }

    if (pDiv == "ongletAssociations") {
        if (document.getElementById('ongletCaracteristiques'))
            document.getElementById('ongletCaracteristiques').style.display = 'none';
        if (document.getElementById('ongletSavoirPlus'))
            document.getElementById('ongletSavoirPlus').style.display = 'none';
        if (document.getElementById('ongletGammes'))
            document.getElementById('ongletGammes').style.display = 'none';
    }

    if (document.getElementById(pDiv))
        document.getElementById(pDiv).style.display = 'block';
}

function displayZoom(picture, product_ref, path_web, picture_medium, picture_mini, photo_path, photo_list) {

    var so = new SWFObject(path_web + "zoom.swf", "zoom", "405", "610", "8", "#FFFFFF");
    so.addParam("wmode", "transparent");
    so.addParam("allowScriptAccess", "always");
    so.addVariable("produit_id", product_ref);
    so.addVariable("url", path_web);

    so.addVariable("photo_path_medium", picture_medium);
    so.addVariable("photo_path_mini", picture_mini);

    so.addVariable("photo_path", photo_path);

    so.addVariable("photo_list", photo_list);
    so.addVariable("img_defaut", picture);
    so.write("flashVisuel");

}

function zoom(picture, product_ref, path_web, picture_medium, picture_mini, photo_path, photo_list) {
    displayZoom(picture, product_ref, path_web, picture_medium, picture_mini, photo_path, photo_list);
}

// function updateZoomedImg(src) {
//
//     $('.main_vis img', this).attr('src', src);
// }

// function zoomImg() {
//
//     var container = $(this).parent().parent('.itm_views');
//     var thumbnail = $('.content_img_visu', container).clone();
//     var main_visu = $(this).clone().removeAttr('id');
//     var zoom_wrap = $('#zoom_box_wrapper');
//     var shade = document.getElementById('shade');
//
//     thumbnail.each(function () {
//         var imgsrc = $('img').attr('src');
//         // Use a regular expression to find a match
//         var checkExist = imgsrc.search(/prodfp/);
//         if (checkExist > -1) {
//             var src = $('img', this).attr('src').replace('/prodfp/', '/large/');
//             src = $('img', this).attr('src').replace('/mini/', '/large/');
//             $(this).removeAttr('id');
//             $('img', this).removeAttr('onclick');
//             this.addEventListener('click', updateZoomedImg.bind(zoom_wrap, src));
//         }
//         else{
//             var src = $('img', this).attr('src');
//             $(this).removeAttr('id');
//             $('img', this).removeAttr('onclick');
//             this.addEventListener('click', updateZoomedImg.bind(zoom_wrap, src));
//         }
//     });
//
//     setTimeout(function(){
//         $('.more_vis', zoom_wrap).lionbars({
//             autohide: true
//         });
//     }, 1000);
//
//     $('#shade').after($('#zoomBox')).add($('#zoomBox')).show();
//
//     addEvent(shade, 'click', closeZoom);
//
//     var actualposition = document.documentElement.scrollTop ,
//     modbox = document.getElementById('zoomBox'),
//     modboxwrapper = document.getElementById('bloc_photo_zoom'),
//     shade = document.getElementById('shade'),
//     cnt_tab_js = tab_js.length,
//     i = 0,
//     is_visu_detail = true,
//     photo_tmp = this.src;
//
//     if (typeof(photo_tmp) != 'undefined') {
//         photo = photo_tmp.replace(/prodfp/, "large");
//     }
//
//     $(modboxwrapper).html('<img id="photo_zoom" />');
//     $(modbox).css('position', 'absolute').add(shade).show();
//
//     var el_zoom_pic = document.getElementById('photo_zoom');
//
//     var myImage = new Image();
//     myImage.src = photo;
//
//     var el_zoom_pic_width = myImage.width;
//     el_zoom_pic_width_demi = (el_zoom_pic_width) / 2;
//
//     modbox.style.margin = "0 0 0 -" + el_zoom_pic_width_demi + "px";
//     modbox.style.top = "137px";
//
//     $('#zoomBox').on('load', function () {
//
//     var picW = $('#photo_zoom').outerHeight(true);
//     var picH = $('#photo_zoom').outerWidth(true);
//
//     $('#bloc_photo_zoom').css({ height : picH , width : picW + 2 });
//     });
//
//     $('img', '#bloc_photo_zoom').css({ marginBottom : "-3px" });
//
//     el_zoom_pic.src = photo;
//
//     setTimeout(function () {
//         if($('#visual_scroller #video_holder').length > 0){
//             $('#btn_video_produit').each(function(){
//                 var getPosition = $('#btn_video_produit').index() + 1;
//                 var clone_video = $('#video_holder #fp_img_content').clone().addClass('zoombox_video').append('<input type="button" onclick="updateToVideo(this);"/>');
//                 clone_video.insertBefore('#wrapper_vgt_visus img:nth-of-type('+getPosition+')');
//             });
//         }
//     }, 150);
//
//     window.scrollTo(0,0);
// }

function zoomImg(slide) {

    $('#zoom_box_wrapper').css('opacity', 0);

    window.setTimeout(function () {

        var zoomMainVisu = $(".zoomMainVisu");
        var zoomThumbs = $(".zoomThumbs");

        zoomThumbs.css('height', $('.thumbsZoomContainer').height());

        zoomThumbs = new Swiper('.zoomThumbs', {

            direction: 'vertical',
            slidesPerView: 'auto',
            spaceBetween: 13,
            roundLengths: true,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            freeMode: true,
            freeModeMomentum: false,
            navigation: {
                prevEl: '.zoomThumbsPrev',
                nextEl: '.zoomThumbsNext',
            },
            breakpoints: {
                1620: {
                    slidesPerView: 5,
                },
            },
        });

        zoomMainVisu = new Swiper('.zoomMainVisu', {

            slidesPerView: 1,
            //effect: 'fade',
            roundLengths: true,
            setWrapperSize: true,
            zoom: {
                maxRatio: 2,
            },
            thumbs: {

                swiper: zoomThumbs
            },
            navigation: {

                prevEl: '.zoomProdPrev',
                nextEl: '.zoomProdNext',
            },
        });

        if (slide == null) {
            if (typeof (productMainVisu) != 'undefined') {
                zoomMainVisu.slideTo(productMainVisu.activeIndex);
            }
        } else {
            if ($('.zoomThumbs .minivideo').length > 0) {
                zoomMainVisu.slideTo(slide);
            } else {
                zoomMainVisu.slideTo(slide);
            }
        }
    }, 250);

    window.setTimeout(function () {

        $('#zoom_box_wrapper').css('opacity', 1);
    }, 350);

    openMultiShad('zoomBox');
}

function changeVisuZoom(field, path) {

    var elem = (document.getElementById('nyroModalImg')) ? 'nyroModalImg' : 'img_tmp';

    var w_tmp = $('#' + elem).width();
    var h_tmp = $('#' + elem).height();

    document.getElementById(field).innerHTML = "<div id='loader_tati' style='height:41px;z-index:1;position:relative;top:400px;text-align:center;font-size:16px;color:#CBE423;'><img src='" + path_relative_root + "img/ajaxLoader.gif' alt=\"Chargement en cours...\" width=\"25\" height=\"25\" /><p><strong>Chargement...</strong></p></div>";
    document.getElementById(field).innerHTML += "<div style='position:relative;z-index:2;top:-41px;'><img id='img_tmp' src=" + path + "></div>";

    $('#img_tmp').on('load', function () {
        $('#loader_tati').remove();
        $(this).parent().css('top', '0');
        $(this).width(w_tmp);
        $(this).height(h_tmp);
        $('img', '#bloc_photo_zoom').css({
            marginBottom: "-3px"
        });
    });
}

function closeZoom() {

    var elname = 'zoomBox';
    var modbox = document.getElementById(elname);
    var shade = document.getElementById('shade');
    var shade_zoom = document.getElementById('shade');
    modbox.style.display = "none";
    shade_zoom.style.display = "";

    delEvent(shade, 'click', closeZoom);
    delEvent(shade_zoom, 'click', closeZoom);
    delEvent(modbox, 'click', closeZoom);

    closemodal();

}

function updateSrc(path_web, dest) {
    var tmp_string_large = document.getElementById('img_large').src;
    tmp_string = tmp_string_large.replace(/medium/, "large");

    document.getElementById('img_large').setAttribute('onclick', 'zoomImg(\'' + tmp_string + '\',\'' + path_web + '\')');
    document.getElementById('loupe').href = 'javascript:zoomImg(\'' + tmp_string + '\',\'' + path_web + '\');';
    $(".zoomLens").css({
        backgroundImage: 'url("' + tmp_string_large + '")'
    });

    if (dest == 'vue') {

        var i = 0;
        var trouve = false;
        while (i < tab_js.length && !trouve) {

            trouve = (tab_js[i][0].substring(tab_js[i][0].lastIndexOf('/') + 1) == tmp_string.substring(tmp_string.lastIndexOf('/') + 1));
            i++;

        }

        cpt_img = (i - 1);

    }

}

function productSendFriendForm(path_web, request_uri) {

    document.getElementById("product_sendfriend_ok").style.display = 'block';
    document.getElementById("product_sendfriend_nok").style.display = 'block';

    if (document.getElementById('sendFriendToMails') && document.getElementById('sendFriendToMessage') && document.getElementById('sendFriendYourName') && document.getElementById('sendFriendYourMail') && document.getElementById('sendFriendCopy')) {
        document.getElementById('sendFriendToMails').style.border = "";
        document.getElementById('sendFriendToMessage').style.border = "";
        document.getElementById('sendFriendYourName').style.border = "";
        document.getElementById('sendFriendYourMail').style.border = "";

        var token = document.getElementById('token_security_sendfriend').value;
        var captcha = document.getElementById('g-recaptcha-response').value;

        var params_add = '';
        if (document.getElementById("produit_id")) {
            params_add += '&product_id=' + document.getElementById("produit_id").value;
        }

        $.ajax({
            url: path_web + create_link('ajax_send_friend_form'),
            type: 'POST',
            data: 'g-recaptcha-response=' + captcha + '&token_security=' + token + '&url=' + request_uri + '&to_mails=' + document.getElementById('sendFriendToMails').value + '&to_message=' + document.getElementById('sendFriendToMessage').value.replace(/\n/g, '<br>') + '&your_name=' + document.getElementById('sendFriendYourName').value + '&your_mail=' + document.getElementById('sendFriendYourMail').value + '&copy=' + document.getElementById('sendFriendCopy').checked + params_add
        }).done(function (response) {

            var content_id = 'product_sendfriend_nok',
                content = '';

            if (response.split(',')[0] == 'ok') {

                var s_adresse = response.substr(3);

                var sing = (response.split(',').length > 2) ? 'sendfriend_ok_plur' : 'sendfriend_ok_sing';

                content = '<div style="font-size:14px;font-weight:bold;font-family:Arial; color:#222;text-align:center;margin-bottom:10px;margin-top:15px;font-family: arial;">' + ajax_file(path_web + 'ajax_sprintf.php?arg1=' + sing) + '</div>';
                content += '<p style=\"margin-top: 0px;margin-bottom: 30px;padding:0;text-align:center;\">' + s_adresse + '</p>';

                content_id = "sendfriend_alert_mail";
                document.getElementById('product_sendfriend_nok').innerHTML = "";
                document.getElementById('sendfriend_prodinfos').style.display = "none";
                document.getElementById('sendfriend_form_to').style.display = "none";
                document.getElementById('sendfriend_form_froms').style.display = "none";
                document.getElementById('sendfriend_form_buttons').style.display = "none";
                document.getElementById('sendfriend_form_needed').style.display = "none";
                document.forms["sendfriendform"].reset();

            } else if (response == '1') {

                content = "<img src=\"" + path_relative_root + "img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web + 'ajax_sprintf.php?arg1=sendfriend_error_no_mail_friends') + "</p>";
                document.getElementById('sendFriendToMails').style.border = "1px #e90000  solid";
                document.getElementById('sendFriendToMessage').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendYourName').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendYourMail').style.border = "1px #e90000 solid";
                $('td.labelForm').addClass('labelErr');

            } else if (response == '2') {

                content = "<img src=\"" + path_relative_root + "img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web + 'ajax_sprintf.php?arg1=sendfriend_error_mail_friends_invalid') + "</p>";
                document.getElementById('sendFriendToMails').style.border = "1px #e90000  solid";
                document.getElementById('sendFriendToMessage').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendYourName').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendYourMail').style.border = "1px #e90000 solid";
                $('td.labelForm').addClass('labelErr');

            } else if (response == '3') {

                content = "<img src=\"" + path_relative_root + "img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web + 'ajax_sprintf.php?arg1=sendfriend_error_no_message') + "</p>";
                document.getElementById('sendFriendToMessage').style.border = "1px #e90000  solid";
                document.getElementById('sendFriendToMails').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendYourName').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendYourMail').style.border = "1px #e90000 solid";
                $('td.labelForm').addClass('labelErr');

            } else if (response == '4') {

                content = "<img src=\"" + path_relative_root + "img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web + 'ajax_sprintf.php?arg1=sendfriend_error_no_name') + "</p>";
                document.getElementById('sendFriendYourName').style.border = "1px #e90000  solid";
                document.getElementById('sendFriendToMails').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendToMessage').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendYourMail').style.border = "1px #e90000 solid";
                $('td.labelForm').addClass('labelErr');

            } else if (response == '5') {

                content = "<img src=\"" + path_relative_root + "img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web + 'ajax_sprintf.php?arg1=sendfriend_error_name_invalid') + "</p>";
                document.getElementById('sendFriendYourName').style.border = "1px #e90000  solid";
                document.getElementById('sendFriendToMails').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendToMessage').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendYourMail').style.border = "1px #e90000 solid";
                $('td.labelForm').addClass('labelErr');

            } else if (response == '6') {

                content = "<img src=\"" + path_relative_root + "img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web + 'ajax_sprintf.php?arg1=sendfriend_error_no_my_mail') + "</p>";
                document.getElementById('sendFriendYourMail').style.border = "1px #e90000  solid";
                document.getElementById('sendFriendToMails').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendToMessage').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendYourName').style.border = "1px #e90000 solid";
                $('td.labelForm').addClass('labelErr');

            } else if (response == '7') {

                content = "<img src=\"" + path_relative_root + "img/picto_panneau.png\" style=\"margin-right:10px;float:left;\" /><p style=\"margin: 0px;padding: 0px; line-height: 24px;\">" + ajax_file(path_web + 'ajax_sprintf.php?arg1=sendfriend_error_my_mail_invalid') + "</p>";
                document.getElementById('sendFriendYourMail').style.border = "1px #e90000  solid";
                document.getElementById('sendFriendToMails').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendToMessage').style.border = "1px #e90000 solid";
                document.getElementById('sendFriendYourName').style.border = "1px #e90000 solid";
                $('td.labelForm').addClass('labelErr');

            } else if (response == 'token_error') {

                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_web + 'ajax_sprintf.php?arg1=error_token_security') + "</p>";

            } else {
                var s_adresse = response.substr(3);
                var sing = (response.split(',').length > 2) ? 'sendfriend_no_ok_plur' : 'sendfriend_no_ok_sing';
                content = '<div style="font-size:14px;font-weight:bold;font-family:Arial; color:#00367B;text-align:center;margin-bottom:10px;margin-top:15px;font-family: EssaiRegular">' + ajax_file(path_web + 'ajax_sprintf.php?arg1=' + sing) + '</div>';
                content_id = "sendfriend_alert_mail";
                content += '<p style="margin:0;padding-top:20px;text-align:center;"><a onclick="closesendfriendmodalfromconfirm()" href="#"><img class="no_border" src="' + path_web + 'img/btn_retour_connexion_top.jpg" /></a><p>';

                document.getElementById('product_sendfriend_nok').innerHTML = "";
                document.getElementById('sendfriend_prodinfos').style.display = "none";
                document.getElementById('sendfriend_form_to').style.display = "none";
                document.getElementById('sendfriend_form_froms').style.display = "none";
                document.getElementById('sendfriend_form_buttons').style.display = "none";
                document.getElementById('sendfriend_form_needed').style.display = "none";

                document.getElementById('sendFriendToMails').value = "";
                document.getElementById('sendFriendToMessage').value = "";
                document.getElementById('sendFriendYourName').value = "";
                document.getElementById('sendFriendYourMail').value = "";
            }

            document.getElementById(content_id).innerHTML = content;
            document.getElementById(content_id).style.display = 'block';

        });
    }

    return false;
}

function prodGetProductDisplay(id, path_web, type) {

    if (typeof (type) == 'undefined') {

        var response = ajax_file(path_web + 'ajax_get_infos_send_friend.php?product_id=' + id);
        document.getElementById('sendfriend_prodinfos').innerHTML = response;

    } else {

        if (type == 'look') {

            $('#titre_popup').html(ajax_file(path_web + 'ajax_sprintf.php?arg1=sendfriend_title_look '));
            $('#friend_form').show();
            $('#btn_popup_panier').hide();

            $('#bloc_btn_look').show();
            $('#bloc_btn_loader_look').hide();

        } else if (type == 'look_basket') {

            $('#titre_popup').html('Vous venez d\'ajouter le look suivant');
            $('#friend_form').hide();
            $('#btn_popup_panier').show();

            $('#bloc_btn_look').show();
            $('#bloc_btn_loader_look').hide();

        } else {

            $('#titre_popup').html('Vous venez d\'ajouter le produit suivant');
            $('#friend_form').hide();
            $('#btn_popup_panier').show();

        }

    }

    if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "hidden";
        }

    }

}

function show_lightbox(lightbox_contact) {

    $('#shade').fadeTo("slow", 0.8).queue(function () {

        $('#' + lightbox_contact).slideDown("slow", function () {

            $('#visu_popup_ajout_panier', this).recalImg();
        });

        $(this).dequeue();
    }).click(function () {

        close_lightbox(lightbox_contact);
    });
}

function close_lightbox(lightbox_contact) {

    $('#' + lightbox_contact).slideUp("slow").queue(function () {

        $('#shade').fadeTo("slow", 0, function () {

            $(this).css('display', 'none');
        });

        $(this).dequeue();
    });
}

function changeColorProduct(id_color, from, prod_id) {

    var txt_add = '';
    if (from == 'produit') {
        txt_add = '_prod';
        id_select = id_color + '_prod';
        var tab = tab_color_border;
    } else if (from == 'assoc') {
        txt_add = '_assoc_' + prod_id;
        window['id_select_assoc_' + prod_id] = id_color + '_assoc_' + prod_id;
        var tab = eval('tab_color_border_assoc_' + prod_id);
        prod_id = '_' + prod_id;
    } else if (from == 'gamme') {
        txt_add = '_gamme_' + prod_id;
        window['id_select_gamme_' + prod_id] = id_color + '_gamme_' + prod_id;
        var tab = eval('tab_color_border_gamme_' + prod_id);
        prod_id = '_' + prod_id;
    }

    document.getElementById('couleurProd' + prod_id).value = id_color;

    var cnt_tab = tab.length;

    for (var i = 0; i < cnt_tab; i++) {

        if (tab[i] == id_color) {
            if (document.getElementById(tab[i] + txt_add))
                document.getElementById(tab[i] + txt_add).style.border = '1px solid #FD6D0C';
        } else {
            if (document.getElementById(tab[i] + txt_add))
                document.getElementById(tab[i] + txt_add).style.border = '1px solid #999';
        }
    }

}

function fbs_click(site, params) {
    if (params == "") {
        params = location.href;
    }

    switch (site) {
        case "fb":
            window.open(
                "http://www.facebook.com/share.php?u=" +
                encodeURIComponent(params) +
                "&t="
            );
            break;
        case "tw":
            window.open("https://twitter.com/share?url=" + params);
            _gaq.push(["_trackSocial", "twitter", "Tweet", params]);
            break;
        case "insta":
            window.open("https://www.instagram.com/hesperide_jardin/");
            break;
        case "pin":
            window.open("http://pinterest.com/pin/create/button/?" + params);
            break;
    }
    return false;
}

function show_video() {
    var video = $(".wrapper_video");
    var video_content = $(".video_content", video);
    var btn_video_produit = $('.btn_video_produit');
    $(window).scrollTop(0);
    $('#shade').fadeIn('slow', function () {
        video.css({
            display: 'block',
            opacity: 0
        }).animate({
            opacity: 1
        });
        if (Modernizr.video) {
            video_content[0].play();
        }
        $('#shade').click(stopVideoSpa);
    });

}

function stopVideoSpa() {
    // Hide iframe
    var video = $(".otherViewImg");
    var btn_video_produit = $('.btn_video_produit');
    var video_content = $(".video_content", video);
    var videoFlv = $("#video_spa_swf");
    $('#shade').fadeOut('slow');

    video.css({
        display: 'none',
        opacity: ''
    });
}

function lightboxAlertStock(id, type_alert) {

    var parent_element = '';
    if ($('#lightbox_achat_express').css('display') == 'block') {
        parent_element = '#lightbox_achat_express ';
    }

    var produit_id = $(parent_element + '#produit_id').val();

    if (!$("#lightbox_stock_alert").is(":visible"))
        var $form = $(parent_element + '#product_selects');
    else
        var $form = $(parent_element + '#form_colisage');


    var $input = $('#' + id, $form); // ID Champs Clique
    var $from = id.split('_')[0];
    var $hidden = $('#' + type + 'Prod', $form);

    var $select = $('#ligne_' + type);
    var $list_inputs = $('.select_input', $select);
    var $list_labels = $('.select_label', $select);

    var data = $form.serialize();
    data += '&produit_id=' + produit_id;
    data += '&from=nothing';
    data += '&type_alert=' + type_alert;

    if ($('#bloc_add_alert').length) {
        $('#bloc_add_alert').addClass('loading')
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_lightbox_alert.php',
        data: data,
        success: function (response) {
            try {
                response = $.parseJSON(response);
            } catch (e) {
                return;
            }

            if (response.success == 'ok') {
                var datas = response.datas;
                var $html = $(datas.html);
                $('#lightbox_stock_alert').html($html);
            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete: function () {
            $('#shade').hide();
            $('#bloc_btn_loader').fadeOut(300);
            if (!$("#lightbox_stock_alert").is(":visible"))
                openMultiShad('lightbox_stock_alert');
            $('.selectStyled').each(function () {
                $(this).selectStyled();
            });

            if ($('#bloc_add_alert').length && $('#bloc_add_alert').hasClass('loading')) {
                $('#bloc_add_alert').removeClass('loading');
            }
        }
    });
}

var button_add_to_wishlist_initial_click_event;
var button_add_to_wishlist_initial_label;

function restoreWishlistButtonState() {
    $("#addToWishlistButton").html(button_add_to_wishlist_initial_label);
    if ($("#addToWishlistButton").hasClass("existToWishlistButton")) {
        $("#addToWishlistButton").removeClass("existToWishlistButton");
    }
    $("#addToWishlistButton").attr("onclick", button_add_to_wishlist_initial_click_event).attr("href", "#");
}

function addToWishlist(event) {
    var btn = $(this);
    var isWishlistBtn = btn.hasClass('existToWishlistButton');
    var wishlistProductId = btn.attr('data-wishlistproductid') ? btn.attr('data-wishlistproductid') : 0;
    var is_product_page = $('body.product_page').length;
    var color_id = btn.attr('data-colorid');
    var size_id = btn.attr('data-sizeid');

    if (isWishlistBtn && !is_product_page) {
        var product_id = btn.attr('data-productid');
        var need_color_id = (color_id.length != 0 && color_id != '0');
        var need_size_id = (size_id.length != 0 && size_id != '0');
    } else {
        var product_id = $("#hdn_product_id").val();
        var is_model = $("#hdn_is_model").val();

        if (color_id == '' || color_id == 0 || typeof(color_id) == 'undefined') {
            color_id = $("#couleurProd").val();
        }

        if (size_id == '' || size_id == 0 || typeof(size_id) == 'undefined') {
            size_id = $("#tailleProd").val();
        }

        var need_color_id = ($("#couleurProd").length != 0 && $("#couleurProd").val() != 'none');
        var need_size_id = ($("#tailleProd").length != 0 && $("#tailleProd").val() != 'none');
    }

    if (typeof (button_add_to_wishlist_initial_click_event) == "undefined") {
        button_add_to_wishlist_initial_click_event = $("#addToWishlistButton").attr("onclick");
    }

    if (typeof (button_add_to_wishlist_initial_label) == "undefined") {
        button_add_to_wishlist_initial_label = $("#addToWishlistButton").html();
    }

    if (!product_id || typeof product_id == "undefined") {
        return false;
    }

    var $shade = $('#shade');
    var $alertbox = $('#alertbox');
    var $alertboxLogin = $('#alertboxlogin');

    var datas = {
        product_id: product_id,
        color_id: color_id,
        size_id: size_id,
        need_color_id: need_color_id,
        need_size_id: need_size_id
    };

    has_errors = false;
    var html = '<div class="txt_alert">' + Translator.translate('please_choose') + ' <span id="label_alert"></span></div><div class="wrapper_btn"><a onclick="closealerterror();" href="#" class="btn_alert"></a></div>';
    if ($alertbox.length == 0) {
        $alertbox = $('<div id="alertbox">' + html + '</div>');
    }

    if (document.getElementById('lightbox_achat_express')) {
        from_range = true;
    } else {
        from_range = false;
    }

    // if the product is already in the WL
    if (parseInt(wishlistProductId) > 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_remove_from_wishlist'),
            type : 'post',
            data: {id: wishlistProductId}
        }).done(function(resp) {
            if (!resp.error) {
                btn.removeClass("existToWishlistButton");
                btn.attr('data-wishlistproductid', '0');
                addToWishlistUpdateHeader(resp.result.wishlistLength, false);
                changeWishlistNb(resp.result.wishlistLength);
            }
        });
    } else {
        $.post(path_relative_root + create_link('ajax_add_to_wishlist') + '?time=' + new Date().getTime(), datas, function (resp) {
            if (resp.error) {
                if ($alertboxLogin.length == 0) {
                    $alertboxLogin = $('<div id="alertboxlogin" class="lightbox sidebox">' + html + '</div>');
                }
                if (resp.message == "login_required") {
                    $alertboxLogin.find(".txt_alert").html(resp.result.error_message);
                    $alertboxLogin.find(".wrapper_btn").html('<span class="close_pop" onclick="closealertloginerror();"></span> <a class="btn_connect alert_btn" href="#" onclick="wishlistRedirectLogin();">' + resp.result.btn_connexion + '</a>');
                    $alertBox = $alertboxLogin;
                } else if (resp.message != "error") {
                    $alertBox = $alertbox;
                    $alertBox.find("#label_alert").html(resp.message);
                } else {
                    if (resp.result.error_message == Translator.translate('err_product_exist_in_wishlist')) {
                        btn.addClass("existToWishlistButton");
                        $("#addToWishlistButton").attr("onclick", "").attr("href", path_relative_root + create_link('client_index') + '#wl');
                    }
                    return false;
                }

                $alertBox.appendTo('body').css('z-index', '10000');
                $shade.show().fadeTo("slow", 0.8);
                $alertBox.addClass('active');
                addEvent($shade, 'click', closeMultiShad);
            } else {
                let wishlistProductId = (resp.result.wishlist_product_id.length && resp.result.wishlist_product_id > 0) ? resp.result.wishlist_product_id : 0;
                btn.attr('data-wishlistproductid', wishlistProductId);
                btn.addClass("existToWishlistButton");
                $("#addToWishlistButton").attr("onclick", "").attr("href", path_relative_root + create_link('client_index') + '#wl');
                addToWishlistUpdateHeader(resp.result.wishlist_size);
                changeWishlistNb(resp.result.wishlist_size);
            }
        });
    }
}

function closealertloginerror() {
    $("#alertboxlogin").removeClass("active");
    $('#shade').fadeOut();
    setTimeout(function () {
        $("#alertboxlogin").remove();
    }, 500);
}

function closealerterror() {
    $("#alertbox1").slideUp(function () {
        $(this).remove();
        $('#shade').fadeOut(function () {
            $(this).remove();
        });
    });
}

function wishlistRedirectLogin() {
    $.get(path_relative_root + "ajax_add_to_wishlist.php?login_required=1", function () {
        document.location.href = path_relative_root + create_link('connexion_login') + "?from=front";
    });
}

var look_add = false;

function addLook(loader) {

    $(loader).fadeIn(200);

    look_add = true;

    calculTotal('slide_look_detail', true);
}

function calculTotal(id, process_look) {

    var $form = $(document.getElementById(id));
    var data = $form.serialize();

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_calcul_total.php',
        data: data,
        success: function (response) {

            try {

                response = JSON.parse(response);
            } catch (e) {

                console.error('parseJSON');

                return;
            }

            if (response.success == 'ok') {

                var datas = response.datas;
                var total = datas.total;

                $('#look_total', '#look_info').val(total);
                $('.price_total', '#look_info').html(datas.total_format);
                var total_vente = datas.total_vente;

                $('#look_total', '#look_info').val(total);
                $('.price_total', '#look_info').html(datas.total_format);
                $('.saleprice').remove();
                if (total_vente > 0 && total_vente != total) {
                    $('<div class="pricetag saleprice">'+datas.total_vente_format+'</div>').insertAfter(".price_total.pricetag");
                }

                if (process_look) {

                    if (total > 0) {

                        if (!addToCartTmpl) {

                            addToCartTmpl = document.getElementById('modbox').innerHTML.trim();

                            Mustache.parse(addToCartTmpl);
                        }

                        buildLookAddedModbox(false);
                        processlook();
                    } else {

                        var $alertbox = $('#alertbox1');

                        res = ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_quantity');

                        html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
                        html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
                        if ($alertbox.length == 0) {
                            $alertbox = $('<div id="alertbox1">' + html_err + '</div>');
                            $('body').append($alertbox.hide());
                        }

                        $alertbox.find('.txt_alert').html(res);
                        openMultiShad('alertbox1', 'fixed');

                        $('#bloc_btn_look').show();
                        $('#bloc_btn_loader_look').hide();

                        if ($('.loader', '#bloc_add_basket').length > 0) {

                            $('.loader', '#bloc_add_basket').hide();
                        }
                    }
                }
            }
        }
    });
}

function loadPrevisu() {

    var cpt = 0,
        $array_product_previsu = [];

    $('.array_produit_total').map(function () {

        var $current_product = $(this).val();
        var $current_product_qte = $('#qteProd_' + $current_product).val();

        if ($current_product_qte > 0) {

            $array_product_previsu[cpt] = [];

            $array_product_previsu[cpt]['id'] = $current_product;
            $array_product_previsu[cpt]['img'] = $('#img_produit_' + $current_product).attr('src');
            $array_product_previsu[cpt]['price'] = $('#price_' + $current_product).html();
            $array_product_previsu[cpt]['qte'] = $current_product_qte;

            cpt++;
        }
    }).get();

    return $array_product_previsu;
}

function processlook() {

    $('.submit_panier').each(function () {

        var $elem = $(this).attr('id');
        var $id = $elem.substring($elem.lastIndexOf("_") + 1);
        var $val_item = $("#qteProd_" + $id).val();

        if ($val_item > 0) {

            $("#look_add_lightbox_" + $id).show();
            $(this).click();
        } else {

            $("#look_add_lightbox_" + $id).hide();
        }
    });

    setTimeout(showsendfriendmodal, 1000, 0, path_relative_root, 'look_basket');
}

function addLookToWishList() {

    // On va checker si tous les champs ont été selectionnés
    var length_look = array_look.length;
    var error = false;
    var i = 0;
    var j = 0;
    var color_id = [];
    var size_id = [];
    var need_color_id = [];
    var need_size_id = [];
    $("#tailleProd").length != 0;
    while (i < length_look && !error) {

        error = (
            (document.getElementById('tailleProd_' + array_look[i]) && document.getElementById('tailleProd_' + array_look[i]).value == '') ||
            (document.getElementById('couleurProd_' + array_look[i]) && document.getElementById('couleurProd_' + array_look[i]).value == '')
        );

        // on ne prend pas en compte la quantite de produit pour la wish list document.getElementById('qteProd_'+array_look[i])

        i++;

    }

    if (error) {
        alert('Vous n\'avez pas sélectionné tous les champs');
    } else {

        while (j < length_look) {
            color_id[j] = ($("#couleurProd_" + array_look[j]).val() != undefined) ? $("#couleurProd_" + array_look[j]).val() : 0;
            size_id[j] = ($("#tailleProd_" + array_look[j]).val() != undefined) ? $("#tailleProd_" + array_look[j]).val() : 0;
            need_color_id[j] = $("#couleurProd_" + array_look[j]).length != 0;
            need_size_id[j] = $("#tailleProd_" + array_look[j]).length != 0;
            j++;
        }

        var $shade = $('#shade');
        var $alertbox = $('#alertbox1');
        var $alertboxLogin = $('#alertboxlogin');
        var data = {}
        data.product_id = array_look;
        data.size_id = size_id;
        data.color_id = color_id;
        data.need_size_id = need_size_id;
        data.need_color_id = need_color_id;

        has_errors = false;
        var html = '<div class="txt_alert"><span id="label_alert"></span></div><div class="rosette"></div>';
        html += '<div class="wrapper_btn"><a onclick="closealerterror();" href="javascript:;" class="btn_alert">Fermer</a></div>';
        if ($alertbox.length == 0) {
            $alertbox = $('<div id="alertbox1">' + html + '</div>');
        }
        var $alertBox;
        var expressBuy = $("#lightbox_achat_express").length != 0;
        if (document.getElementById('lightbox_achat_express'))
            from_range = true;
        else
            from_range = false;

        $.post(
            path_relative_root + 'ajax_add_look_to_wishlist.php?time=' + new Date().getTime(),
            data,
            function (resp) {
                if (resp.error) {
                    if ($alertboxLogin.length == 0) {
                        $alertboxLogin = $('<div id="alertboxlogin">' + html + '</div>');
                    }
                    if (resp.message == "login_required") {
                        $alertboxLogin.find(".txt_alert").html(resp.result.error_message);

                        $alertboxLogin.find(".wrapper_btn").html('<a class="btn_connect alert_btn f_right" href="#" onclick="wishlistRedirectLogin();">' + resp.result.btn_connexion + '<span></span></a> <span class="btn_close alert_btn f_left" href="#" onclick="closealertloginerror();">' + resp.result.btn_annuler + '</span><div class="c_both"></div>');
                        $(document).scrollTop(0);
                        $alertBox = $alertboxLogin;
                    } else if (resp.message == "error") {
                        $alertBox = $alertbox;
                        $alertBox.find("#label_alert").html(resp.result.error_message);
                        $(document).scrollTop(0);
                    }

                    $shade.appendTo('body').show();
                    $alertBox.appendTo('body').show().css('z-index', '10000');
                    var shade = document.getElementById('shade');
                    addEvent(shade, 'click', closeMultiShad);
                } else {

                    $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");
                    $("#addToWishlistButton").parent().addClass('sendToWishlist');
                    $("#addToWishlistButton").attr("onclick", "").attr("href", path_relative_root + create_link('client_index') + '#wl');
                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                }
            }
        );

    }

}


function removeThumb(id) {

    var select = document.getElementById('qteProd_' + id);

    this.className += ' closing';

    window.setTimeout(function () {

        select.value = parseFloat(select.value) - 1;

        $(select).change();
    }, 160);
}

function createThumb(data) {

    var template = document.getElementById('thumb_tpe').innerHTML,
        rendered = Mustache.render(template, data),
        new_item = document.createElement('li');

    new_item.className = 'itm_thumb';
    new_item.innerHTML = rendered;

    document.getElementById('itm_thumb_list').appendChild(new_item);
}

function parseThumbList(list) {

    var thumb, qte, i;

    for (thumb in list) {

        if (list.hasOwnProperty(thumb)) {

            thumb = list[thumb];
            qte = thumb.qte;

            delete thumb.qte;

            for (i = 0; i < qte; i += 1) {

                createThumb(thumb);
            }
        }
    }
}

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }

    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

function refreshEventsLoadProduct() {
    if ($(".product_page")) {

        var toggleDescr = $("#toggleDescr");
        var toggleSpecs = $("#toggleSpecs");
        var descrContent = $("#descrContent");
        var caracContent = $("#caracContent");

        if (toggleDescr != undefined) {
            toggleDescr.on("click", function () {

                toggleDescr.addClass("actif");
                toggleSpecs.removeClass("actif");
                descrContent.addClass("actif");
                caracContent.removeClass("actif");
            });
        }

        if (toggleDescr != undefined) {
            toggleSpecs.on("click", function () {

                toggleDescr.removeClass("actif");
                toggleSpecs.addClass("actif");
                descrContent.removeClass("actif");
                caracContent.addClass("actif");
            });
        }
    }
}

function implementTagNxtPerf(list_ref) {

    var url_nextPerf = "https://nxtck.com/act.php?tag=40344&pid=";

    if ($('script[src*="' + url_nextPerf + '"]').length === 0) {
        var nextPerf = document.createElement("script");
        nextPerf.type = "text/javascript";
        nextPerf.src = url_nextPerf + list_ref;
        nextPerf.async = true;

        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(nextPerf, s);
    }

}

/*# FIX SIDE NAV #*/
function fix_filter(s_element, s_offset, recalc, h) {

    var elem = $(s_element);

    if (elem.length) {

        if (recalc) {

            elem.stick_in_parent({
                inner_scrolling: false,
                offset_top: h,
                recalc_every: 1
            });
        } else {

            elem.stick_in_parent({
                inner_scrolling: false,
                offset_top: h
            });
        }
    }
}

function fix_elements() {

    fix_filter('.fix_product_info_wrapper', '#site_head_wrap', true, 140);
}

window.addEventListener('scroll', fix_elements);

$(fix_elements);



/**
 * FP main visu slider
 */
let intervalId = null;
var spacer = 40;

function setHeightFpSliders() {
    var mainSlideFp = $(".mainSlideFp");
    var thumbSlideFp = $(".thumbSlideFp");

    if (thumbSlideFp.outerHeight() !== (mainSlideFp.find('.img_visu').height() - spacer)) {
        //Set height
        $('.thumbsFpContainer').css('height', mainSlideFp.find('.img_visu').height());
        thumbSlideFp.css('height', (mainSlideFp.find('.img_visu').height() - spacer));
    }
    else {
        //If height set, remove interval
        clearInterval(intervalId);
    }
}

function initFpSliders() {

    /**
 * Product main slider
 */
    setTimeout(function() {
        if ($(".mainSlideFp").length) {
            var mainSlideFp = $(".mainSlideFp");
            var thumbSlideFp = $(".thumbSlideFp");

            setHeightFpSliders();

            if (thumbSlideFp.outerHeight() !== (mainSlideFp.find('.img_visu').height() - spacer)) {
                //If height not correctly defined, run function on constant interval
                intervalId = setInterval(setHeightFpSliders, 500);
            } else {
                //If height set, remove interval
                clearInterval(intervalId);
            }

            const thumbSlideFpSwiper = new Swiper('.thumbSlideFp', {
                direction: 'vertical',
                slidesPerView: 3.6,
                spaceBetween: 10,
                roundLengths: true,
                watchSlidesProgress: true,
                watchSlidesVisibility: true,
                freeMode: true,
                navigation: {
                    prevEl: '.thumbsFpContainer .thumbPrev',
                    nextEl: '.thumbsFpContainer .thumbNext',
                },
                on: {
                    init : function() {
                        $('.thumbPrev, .thumbNext', '.thumbsFpContainer').removeClass('cache');
                    },
                },
                breakpoints: {
                    1582: {
                        slidesPerView: 3.5,
                    },

                    1300: {
                        slidesPerView: 3.4,
                    },

                    1200: {
                        slidesPerView: 3.3,
                    }
                }
            });

            // Bring the selected thumb slide to the center
            $('.thumbSlideFp .swiper-slide').on('click', function () {
                const realIndex = thumbSlideFpSwiper.clickedIndex;
                const currentIndex = thumbSlideFpSwiper.activeIndex;
                const slidesPerView = thumbSlideFpSwiper.params.slidesPerView;
                const middleIndex = Math.floor(slidesPerView / 2);

                let newIndex = realIndex - middleIndex;

                // Check if we are sliding upwards
                if (realIndex === currentIndex) {
                    newIndex -= 1 ;
                }

                thumbSlideFpSwiper.slideTo(newIndex, 400);
            });

            const fpSwiper = new Swiper('.mainSlideFp', {
                slidesPerView: 1,
                // effect: 'fade',
                roundLengths: true,
                setWrapperSize: true,
                allowTouchMove: false,
                thumbs: {
                    swiper: thumbSlideFpSwiper
                }
            });

            // Update swipers height on resize --> delay it so it can correctly update thumbs container's size
            $(window).on('resize', function () {
                setTimeout(function() {
                    setHeightFpSliders();

                    thumbSlideFpSwiper.update();
                    fpSwiper.update();
                }, 500);
            });
        }
    }, 250);

    $('.choices_list').each(function() {
        let zis = $(this);
        let parent = $(this).parent('.list_container');
        new Swiper(zis[0], {
            slidesPerView: 'auto',
            roundLengths: true,
            setWrapperSize: true,
            spaceBetween: 10,
            navigation: {
                prevEl: parent.find('.choices_list_prev')[0],
                nextEl: parent.find('.choices_list_next')[0],
            },
            on: {
                init: function () {
                    parent.find('.choices_list_prev').removeClass('cache');
                    parent.find('.choices_list_next').removeClass('cache');
                },
            },
        });
    })

    if ($('#associations_by_product_slider').length) {
        new Swiper('#associations_by_product_slider', {
            slidesPerView: 3,
            roundLengths: true,
            setWrapperSize: true,
            spaceBetween: 15,
            navigation: {
                prevEl: '.associations_by_produit .assoc_prev',
                nextEl: '.associations_by_produit .assoc_next',
            },
            on: {
                init: function () {
                    $('.assoc_prev, .assoc_next', '.associations_by_produit').removeClass('cache');
                },
            },
            breakpoints: {
                1400: {
                    slidesPerView: 2,
                }
            }
        });
    }

    // "Vous aimerez aussi" / You may like
    if ($('#assocs_slider').length > 0) {
        var swiper = new Swiper('#assocs_slider', {
            spaceBetween: 45,
            slidesPerView: 5,
            loopedSlides: 0,
            navigation: {
                prevEl: '.you_may_like_container .assoc_prev',
                nextEl: '.you_may_like_container .assoc_next',
                hiddenClass: '.swiper-button-hidden'
            },
            on: {
                init: function () {
                    $('.assoc_prev, .assoc_next', '.you_may_like_container').removeClass('cache');
                },
            },
            breakpoints: {
                1400: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: 3
                }
            }
        });
    }

    // "Récemment consultés"
    productVisitedSwiper(5);

    // "Récemment consultés : Page Panier"
    if($("body.cart.step_1").length) {
        productVisitedSwiper(3);
    }
}

function productDescAnimation() {
    $('.desc_pannel .title_container').on('click', function () {
        let parent = $(this).parent('.desc_pannel');

        if (!parent.hasClass('collapsed')) {
            parent.addClass('collapsed')
            parent.find('>.collapsible').slideUp(300);
        } else {
            parent.find('>.collapsible').slideDown(300);
            parent.removeClass('collapsed');
        }
    });
}

// Inject selected color in product right part
function injectChosenColor() {
    if ($('#ligne_couleur .user_product_choice').length && $('.form_itm.color.checked').length) {
        $('#ligne_couleur .chosen_color').html($('.form_itm.color.checked label').attr('title'));
    }
}
// Inject hovered color in product right part
function injectHoverColor(event) {
    if ($(event.target).hasClass('color_itm_label')) {
        if ($('#ligne_couleur .user_product_choice').length) {
            $('#ligne_couleur .chosen_color').html($(event.target).attr('title'));
        }
    }
}

function promo_injectHoverColor(event) {
    if ($(event.target).hasClass('promo_color_label')) {
        if ($('.selection-offer-content .promo_color_choice').length) {
            $('.selection-offer-content .chosen_color').html($(event.target).attr('title'));
        }
    }
}

function promo_injectChosenColor(event) {
    if ($('.selection-offer-content .chosen_color').length) {
        if ($('.selection-offer-content .prodColor:checked').length) {
            $('.selection-offer-content .chosen_color').html($('.selection-offer-content .prodColor:checked+.promo_color_label').attr('title'));
        } else {
            $('.selection-offer-content .chosen_color').html('')
        }
    }
}


function loadProductSelectionOffert() {

    var product_id = $(this).data('product-id');
    var product_id_parent = $(this).data('product-parent-id');
    var product_color = $(this).val();
    var $form = $(this).parents('form');
    var $img = $form.find('.product_img_wrapper');

    if (!$(this).parents('.js-form-selection-offer').hasClass('actif')) {
        selectionOffert.apply($(this).parents('.js-form-selection-offer')[0]);
    }

    if (product_id > 0 && product_color > 0) {

        data = {

            produit_id : product_id,
            product_color : product_color,
        };

        $.ajax({

            type: 'post',
            url: path_relative_root + 'ajax_get_product_selection_offert.php',
            data: data,
            success: function (res) {

                try {

                    res = $.parseJSON(res);
                } catch (e) {

                    console.error('parseJSON');
                    return;
                }

                if (res.success && res.stock > 0) {
                    $img.find('img').attr('src', $('#color_sel_offer_' + product_id + '_' + product_color).attr('data-pic'));
                    $form.find('input[name="product_id"]').val(product_id);
                    if (product_id_parent) {
                        $.each(res.sizes, function(size_id, has_stock) {
                            if (has_stock) {
                                $('#div_size_sel_offer_' + product_id_parent + '_' + size_id).removeClass('cache');
                            } else {
                                $('#div_size_sel_offer_' + product_id_parent + '_' + size_id).addClass('cache');
                            }
                        });
                    }

                } else {
                    $(this).prod('checked',false);
                }
            }
        });
    }
}

function displayCaracPic(event) {
    $('.product_notice .notice_image').on('click', function(){
        let carac_pic = $(this);

        let carac_pic_src = carac_pic.attr('src')
        $('.desc_pic_zoom .pic_holder img').attr('src', '');
        $('.desc_pic_zoom .pic_holder img').attr('src', carac_pic_src);

        // Once image is updated, open lightbox zoom
        openMultiShad('desc_pic_zoom');
    })
}

/* Product page custom scripts ****************/
$(function() {
    productDescAnimation();

    // Init sliders on product page
    initFpSliders();

    // Inject selected color in product right part
    injectChosenColor();

    // Enable script for carac pic zoom on click
    displayCaracPic();
});
