$(function() {

    /* Sent wishlist success event from vue form */
    $(window).on('vue_send_wishlist_success', function() {
        if ($('#sendwishlistbox').length) {
            $('#sendwishlistbox').addClass('send_wishlist_success')
        }
    })

    $(window).on('vue_send_wishlist_reload_bloc', function() {
        if ($('#sendwishlistbox.send_wishlist_success').length) {
            $('#sendwishlistbox').removeClass('send_wishlist_success')
        }
    })
});



/*
* Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
* product_right.php, wishlist_index.php
*/

/*
* Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
*/
function changeBtnAddWishlist(in_wishlist) {
    if (in_wishlist) {
        $("#addToWishlistButton").attr("onclick", "");
        $("#addToWishlistButton").attr("href", path_relative_root + create_link('wishlist'));
        $("#addToWishlistButton").addClass("existToWishlistButton");
    } else {
        $("#addToWishlistButton").attr("onclick", button_add_to_wishlist_initial_click_event);
        $("#addToWishlistButton").attr("href", "javascript:;");
        $("#addToWishlistButton").removeClass("existToWishlistButton");
    }
}

function wishlistRedirectLogin(is_inspi = false){
    let data = "ajax_add_to_wishlist.php?login_required=1";
    if (is_inspi) {
        data += "&is_inspi=1";
    }
    $.get(path_relative_root + data, function() {
        document.location.href=path_relative_root + create_link('connexion_login') + "?from=front";
    });
}

function addToWishlistUpdateHeader(nr, showRollover = true) {
    $(".btn_top_wishlist .gris").html('(x' + nr + ')');

    if (nr == 0) {
        $("#basket1").show();
        $("#basket2").hide();
        $(".btn_top_wishlist").hide();
        $("#number_wishlist_top").html('(0)');
    } else {
        $("#basket2").show();
        $("#basket1").hide();
        $(".btn_top_wishlist").show();
        $("#number_wishlist_top").html('(x' + nr + ')');
    }

    var response_html = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));
    $("#wishlist_top").html(response_html);

    if (showRollover == true) {
        animateWishlistRollover();
    }
}

function animateWishlistRollover() {
    let wishlist_menu_entry = '#right_part_wishlist';
    let wishlist_list = '#right_part_wishlist .cart_product_listing';

    updateListScrollbar(wishlist_list);

    $(wishlist_menu_entry).addClass('hover', function(){
        setTimeout(function(){
            $(wishlist_menu_entry).removeClass('hover');
        }, 2000);
    });

    if (typeof triggerShad !== undefined){
        triggerShad('menu', 'add');
        setTimeout(function(){
            setTimeout(function(){
                if (!isVisible("#right_part_wishlist .cart_box")) {
                    triggerShad('menu', 'remove');
                }
            }, 300);
        }, 2000);
    }
}

function updateListScrollbar(element) {
    if ($(element).length) {
        $(element).overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

}

function scanForChanges() {
    var totalPrice = 0;
    totalPoints = 0;
    selectedProducts = new Array();
    $(".checkbox_select_product").each(function() {
        var element = $(this);
        var id = element.attr("data-id");

        if (element.is(':checked')) {
            var price = parseFloat(element.attr('data-price'));
            element.css('background-position','top');

            if (price) {
                totalPrice += price;
            }

            selectedProducts.push(id);
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }
        }
        else {
            element.css('background-position','bottom');
        }
    });

    var txt = "",
    count = '<span class="wishlist_count"></span> ',
    text = "";
    var priceSpan = $("#total_price");

    switch (selectedProducts.length) {
        case 0:
        txt = $texts[0] + ' :';
        priceSpan.html('0<sup>&euro;</sup><span class="decimal_price"></span>');
        break;
        case 1:
        text = $texts[1];
        default:
        if (text == '')
            text = $texts[2];

        pricefloor = Math.floor(totalPrice);

        if (pricefloor == totalPrice) {
            decimal = '';
        } else {
            decimal = Math.round((totalPrice - pricefloor) * 100);
            decimal = '' + decimal;
            if (decimal.length == 1) {
                decimal = decimal + '0';
            }
        }

        priceSpan.html('<span id="total_price">' + pricefloor + '<sup class="devise">&euro;</sup><sup></sup><span class="decimal_price">' + decimal + '</span></span>');
        txt = text;
        count = '<span class="wishlist_count">' + selectedProducts.length + '</span> ';
    }

    $(".txt","#nrArticlesSelected").html(txt);
    $("#blocCount","#nrArticlesSelected").html(count);
}

function addProductToCart(wpId, pId, sId, cId)
{
    $.post(path_relative_root + create_link('ajax_wishlist_add_product_to_cart'),
    {
        product_id: pId,
        size_id: sId,
        color_id: cId
    }, function(resp) {
        if (resp == "ok") {
            document.location.href= path_relative_root + create_link('order_basket');
        } else {
            if ($("#ckwp_" + wpId).attr("pointscadeau")) {
                alert(Translator.translate('err_no_points'));
            }
        }
    });
}

/**
* Ferme une popup par rapport à son nom et ferme aussi le shad
* Utilisation : #sendwishlistbox qui est généré dinamiquement dans wishlist_index.php
*/
function closePopup( popup_id, shad_id ){
    if ( shad_id == undefined ){
        shad_id = 'shad';
    }

    var $popup = $( '#' + popup_id ),
    $shad = $( '#' + shad_id );

    $shad.unbind( 'click.popup' );
    $popup.slideUp( 'slow', function(){
        $shad.fadeOut( 'slow' ,function(){
            if($('#wrapper_sendwishlist').hasClass('sended')){
                $('#sendwishlistbox').remove();
            }
        });
    });
}

/**
* Affiche la lighbox de la wishlist, dynamiquement si la box n'existe pas
* Utilisation : wishlist_index.php
*/
function showsendwishlistmodal(){
    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist') + '?liste_produit=' + selectedProducts,
            success : function(response){
                if($('#sendwishlistbox').length == 0){
                    $('body').append(response);
                    if ($("#g-recaptcha").length > 0) {
                        grecaptcha.render("g-recaptcha");
                    }
                    $(function () {
                        $('.styled').new_styling_form({
                            type_class_sufix: '',
                            localName_new_elem: 'span'
                        });
                    });
                }
            },
            complete : function(){
                // reload vue form
                if ($('#sendwishlistbox').hasClass('send_wishlist_success') && (typeof wShop !== 'undefined' && wShop.$refs.wSendwishlistForm)) {
                    wShop.$refs.wSendwishlistForm.reloadBloc();
                }
                openMultiShad('sendwishlistbox');
                $(".choice_elem", ".choose_theme").click(
                    function(event) {
                        var $obj = $(this);
                        var dValue = $obj.attr("data-value");
                        if (!$obj.hasClass("select")) {
                            $(
                                ".choice_elem",
                                ".choose_theme"
                            ).removeClass("select");
                            $obj.addClass("select");
                            $("#theme").attr("value", dValue);
                        }
                    }
                );
            }
        });
    } else {
        // reload vue form
        if ($('#sendwishlistbox').hasClass('send_wishlist_success') && (typeof wShop !== 'undefined' && wShop.$refs.wSendwishlistForm)) {
            wShop.$refs.wSendwishlistForm.reloadBloc();
        }
        openMultiShad('sendwishlistbox');
    }
}


/**
* Envoie le formulaire de la lightbox wishlistavec gestion des erreurs
* Utilisation : wishlist_index.php
*/
function sendWishListForm() {

    $("#sendWishListSubmit").css({display : 'none'});
    $('#wrapper_loader_send').css({display : 'block'});
    var content_id = $("#send_wishlist_ajax");
    var content;
    if ( $('#sendWishListToMails').length && $('#sendWishListToMessage').length && $('#sendWishListYourName').length && $('#sendWishListYourMail').length ) {

        var to_mails = $('#sendWishListToMails').val();
        var to_message = $('#sendWishListToMessage').val();
        var your_name = $('#sendWishListYourName').val();
        var your_mail = $('#sendWishListYourMail').val();
        var token_security = $('#token_security_wishlist').val();
        var captcha = $("#g-recaptcha-response").val();
        var is_message_required = false; // WP-27674
        var is_name_required = false; // WP-27674

        $.ajax({
            url:
            path_relative_root +
            create_link("ajax_send_wishlist_form") +
            "?to_mails=" +
            to_mails +
            "&to_message=" +
            to_message +
            "&your_name=" +
            your_name +
            "&your_mail=" +
            your_mail +
            "&token_security=" +
            token_security +
            "&g-recaptcha-response=" +
            captcha +
            "&is_message_required=" +
            is_message_required +
            "&_is_name_required=" +
            is_name_required
        }).done(function(response) {
            $(".zone_text").css({ border: "" });

            if (response.split(",")[0] == "ok") {
                var s_adresse = response.substr(3);

                var sing =
                response.split(",").length > 2
                ? "sendfriend_ok_plur"
                : "sendfriend_ok_sing";

                content =
                "<div>" +
                ajax_file(
                    path_relative_root +
                    create_link("ajax_sprintf") +
                    "?arg1=" +
                    sing
                ) +
                "</div>";
                content +=
                '<p style="margin-top: 10px;margin-bottom: 30px;padding:0;text-align:center;">' +
                s_adresse +
                "</p>";
            }
            if (response == "1" || response == "2") {
                content =
                '<p class="txt_err_sendfriend">' +
                ajax_file(
                    path_relative_root +
                    create_link("ajax_sprintf") +
                    "?arg1=sendfriend_error_mail_friends_invalid"
                ) +
                "</p>";
                $("#sendWishListToMails").addClass("inputErr");
            } else {
                $("#sendWishListToMails").removeClass("inputErr");
            }
            if (response == "3") {
                content =
                '<p class="txt_err_sendfriend">' +
                ajax_file(
                    path_relative_root +
                    create_link("ajax_sprintf") +
                    "?arg1=sendfriend_error_no_message"
                ) +
                "</p>";
                $("#sendWishListToMessage").addClass("inputErr");
            } else {
                $("#sendWishListToMessage").removeClass("inputErr");
            }
            if (response == "4") {
                content =
                '<p class="txt_err_sendfriend">' +
                ajax_file(
                    path_relative_root +
                    create_link("ajax_sprintf") +
                    "?arg1=sendfriend_error_no_name"
                ) +
                "</p>";
                $("#sendWishListYourName").addClass("inputErr");
            } else {
                $("#sendWishListYourName").removeClass("inputErr");
            }
            if (response == "5" || response == "6") {
                $("#sendWishListYourMail").addClass("inputErr");

                if (response == "5") {
                    content =
                    '<p class="txt_err_sendfriend">' +
                    ajax_file(
                        path_relative_root +
                        create_link("ajax_sprintf") +
                        "?arg1=sendfriend_error_no_my_mail"
                    ) +
                    "</p>";
                } else {
                    content =
                    '<p class="txt_err_sendfriend">' +
                    ajax_file(
                        path_relative_root +
                        create_link("ajax_sprintf") +
                        "?arg1=sendfriend_error_my_mail_invalid"
                    ) +
                    "</p>";
                }
            } else {
                $("#sendWishListYourMail").removeClass("inputErr");
            }

            if (response == "token_error") {
                content =
                '<p class="txt_err_sendfriend">' +
                ajax_file(
                    path_relative_root +
                    create_link("ajax_sprintf") +
                    "?arg1=error_token_security"
                ) +
                "</p>";
            }

            if (response.split(",")[0] == "7") {
                var s_adresse = response.substr(3);
                var sing =
                response.split(",").length > 2
                ? "sendfriend_no_ok_plur"
                : "sendfriend_no_ok_sing";
                content =
                "<div>" +
                ajax_file(
                    path_relative_root +
                    create_link("ajax_sprintf") +
                    "?arg1=" +
                    sing
                ) +
                "</div>";
            }
            if (
                response.split(",")[0] == "7" ||
                response.split(",")[0] == "token_error" ||
                response.split(",")[0] == "ok"
            ) {
                content_id = $("#sendfriend_alert_mail");

                $("#sendfriend_alert_mail").css({
                    display: "block",
                    opacity: 0
                });
                $("#wrapper_sendwishlist")
                .addClass("sended")
                .css({ display: "block", opacity: 1 })
                .animate({ opacity: 0 }, function() {
                    $("#wrapper_sendwishlist").css({ display: "none" });
                    $("#sendfriend_alert_mail").animate(
                        { opacity: 1 },
                        function() {
                            $("#sendfriend_alert_mail").css({
                                display: "block"
                            });
                        }
                    );
                });
            } else {
                $("#sendfriend_form_buttons").removeClass("loading");
            }

            content_id.html(content).css({ display: "block" });
        });

    }

    return false;
}

function addToWishlistRay() {
    var btn = $(this);
    var idElt = btn.data('idelt');
    var isWishlistBtn = btn.hasClass('addToWishlistButton');
    var wishlistProductId = btn.attr('data-wishlistproductid') ? btn.attr('data-wishlistproductid') : 0;
    var color_id = btn.attr('data-product-color-id');
    var size_id = btn.attr('data-product-size-id');

    if (color_id == '' || color_id == 0 || typeof(color_id) == 'undefined') {
        color_id = $('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd_' + product_id).val();
    }

    if (size_id == '' || size_id == 0 || typeof(size_id) == 'undefined') {
        size_id = $( '#tailleProd_' + idElt ).length == 1 ? $( '#tailleProd_' + idElt ).val() : $( '#tailleProd_' + product_id ).val();
    }

    if (isWishlistBtn) {
        var product_id = btn.attr('data-productid');
        var need_color_id = (color_id != 0 && color_id != '0');
        var need_size_id = (size_id.length != 0 && size_id != '0');
    } else {
        var product_id = $("#hdn_product_id").val();
        var is_model = $("#hdn_is_model").val();
        var need_color_id = ($("#couleurProd").length != 0 && $("#couleurProd").val() != 'none');
        var need_size_id = ($("#tailleProd").length != 0 && $("#tailleProd").val() != 'none');
    }

    if (typeof (button_add_to_wishlist_initial_click_event) == "undefined") {
        button_add_to_wishlist_initial_click_event = $("#addToWishlistButton").attr("onclick");
    }

    if (typeof (button_add_to_wishlist_initial_label) == "undefined") {
        button_add_to_wishlist_initial_label = $("#addToWishlistButton").html();
    }

    if (!product_id || typeof product_id == "undefined") {
        return false;
    }

    var $shade = $('#shade');
    var $alertbox = $('#alertbox');
    var $alertboxLogin = $('#alertboxlogin');

    var datas = {
        product_id: product_id,
        color_id: color_id,
        size_id: size_id,
        need_color_id: need_color_id,
        need_size_id: need_size_id
    };

    has_errors = false;
    var html = '<div class="txt_alert">veuillez choisir une <span id="label_alert"></span></div>';
    html += '<div class="wrapper_btn"><a onclick="closealerterror();" href="#" class="btn_alert"></a></div>';
    if ($alertbox.length == 0) {
        $alertbox = $('<div id="alertbox">' + html + '</div>');
    }

    var expressBuy = $("#lightbox_achat_express").length != 0;
    if (document.getElementById('lightbox_achat_express')) {
        from_range = true;
    } else {
        from_range = false;
    }

    // if the product is already in the WL
    if (parseInt(wishlistProductId) > 0) {

        $.ajax({
            url: path_relative_root + create_link('ajax_remove_from_wishlist'),
            type : 'post',
            data: {id: wishlistProductId}
        }).done(function(resp) {
            if (!resp.error) {
                btn.removeClass("in_wishlist");
                btn.removeClass("existToWishlistButton");
                btn.attr('data-wishlistproductid', '0');
                addToWishlistUpdateHeader(resp.result.wishlistLength, false);
                changeWishlistNb(resp.result.wishlistLength);
            }
        });
    } else {
        $.post(path_relative_root + create_link('ajax_add_to_wishlist') + '?time=' + new Date().getTime(), datas, function (resp) {

            if (resp.error) {
                if ($alertboxLogin.length == 0) {
                    $alertboxLogin = $('<div id="alertboxlogin" class="lightbox sidebox">' + html + '</div>');
                }
                if (resp.message == "login_required") {
                    $alertboxLogin.find(".txt_alert").html(resp.result.error_message);
                    $alertboxLogin.find(".wrapper_btn").html('<span class="close_pop" onclick="closealertloginerror();"></span> <a class="btn_connect alert_btn" href="#" onclick="wishlistRedirectLogin();">' + resp.result.btn_connexion + '</a>');
                    $alertBox = $alertboxLogin;
                } else if (resp.message != "error") {
                    $alertBox = $alertbox;
                    $alertBox.find("#label_alert").html(resp.message);
                } else {
                    return false;
                }

                // $shade.show().fadeTo("slow", 0.8);
                // $alertBox.appendTo('body').hide().css('z-index', '10000');
                // $alertBox.slideDown();
                // addEvent($shade, 'click', closeMultiShad);
                $alertBox.appendTo('body').css('z-index', '10000');
                $shade.show().fadeTo("slow", 0.8);
                $alertBox.addClass('active');
                addEvent($shade, 'click', closeMultiShad);
            } else {

                $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");
                $("#addToWishlistButton").attr("onclick", "").attr("href", path_relative_root + create_link('client_index') + '#wl');
                btn.attr("data-wishlistproductid", resp.result.wishlist_product_id);
                addToWishlistUpdateHeader(resp.result.wishlist_size);
                changeWishlistNb(resp.result.wishlist_size);
                if (isWishlistBtn) {
                    btn.toggleClass('in_wishlist');
                }

            }
        });
    }
}

function moveProductFromWishlistToCart(array_wishlist_product_ids) {
    if (array_wishlist_product_ids.length > 0 && array_wishlist_product_ids.length < 2) {
        // If there is only one product to add in bakset add loading class to its add btn
        if ($('#wproduct_' + array_wishlist_product_ids[0]).length && $('#wproduct_' + array_wishlist_product_ids[0] + ' .cta_add_to_basket .loader').length) {
            $('#wproduct_' + array_wishlist_product_ids[0] + ' .cta_add_to_basket .loader').addClass('loading');
        }
    }
    $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), {data: array_wishlist_product_ids.join(",")}, function(resp) {
        if (resp == "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

function remove_product_from_wishlist(id, callback) {
    $.post(path_relative_root + create_link("ajax_remove_from_wishlist"), { id: id }, callback);
}

function ajax_RemoveWishlistProd(id) {
    remove_product_from_wishlist(id, function(data) {
        if (data.result) {
            var wishlistLength = data.result.wishlistLength;
            addToWishlistUpdateHeader(wishlistLength, false);
            changeWishlistNb(wishlistLength);
            $('#show_top_wish .remove_top_wishlist[data-id=' + id + ']').parents('.product-link').remove();
        }
    });
}